export const initialClients = [
  {
    id: 0,
    label: 'Absence',
    color: '#CCC',
    description: 'Congés, arrêt maladie, etc...',
    consider: true,
    holiday: true,
  },
];
