export const initialState = {
  clipboard: null,
  loading: false,
  tasksList: {
    // uid1: {
    //   time: [10, 10.5],
    //   clientId: 0,
    //   description: 'azerty',
    //   date: '2020-10-23',
    // },
    // uid2: {
    //   time: [9, 15],
    //   clientId: '-MK980umHMIymkBQN2_x',
    //   description: 'aaaazzzzz',
    //   date: '2020-10-22',
    // },
    // uid3: {
    //   time: [11, 13],
    //   clientId: '-MK980umHMIymkBQN2_x',
    //   description: 'qsdfghj',
    //   date: '2020-10-23',
    // },
  },
};
